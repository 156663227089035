.SkillsContent {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  width: 100%;
  /* height: 100%; */
}

.SkillsList {
  display: flex;
  /* height: 50%; */
  flex-direction: column;
  line-height: 2rem;
  /* background-color: darkgreen; */
}

@media (max-width: 415px) {
  .SkillsContent {
    margin-top: 10%;
  }
}

@media (max-width: 376px) {
  .SkillsList {
    /* height: 100%; */
    /* height: fit-content; */
  }
}