@import url(https://fonts.googleapis.com/css?family=Source+Code+Pro);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: flex;
  text-align: center;
  height: 100%;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(49, 49, 49);
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
  font-size: 1rem;
}

.Intro {
  display: flex;
  height: 100vh;
  width: 97vw;
  align-content: center;
  justify-content: center;
  text-align: center;
  color: white;
  background-color: rgb(49, 49, 49);
}

.IntroContent {
  display: flex;
  flex-direction: column;
  /* background-color: black; */
  width: 330px;
}

.IntroTyping p {
  border-right: 0.15em solid cyan;
  font-family: "Source Code Pro";
  font-size: 28px;
  white-space: nowrap;
  overflow: hidden;
}

.IntroTyping p:nth-child(1) {
  margin-left: 20%;
  width: 200px;
  /* manually set width */
  -webkit-animation: type 2s steps(40, end);
  animation: type 2s steps(40, end);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.IntroTyping p:nth-child(2) {
  width: 335px;
  /* manually set width */
  opacity: 0;
  -webkit-animation: type3 5s steps(40, end),
    blink 0.5s step-end infinite alternate;
  animation: type3 2s steps(40, end), blink 0.5s step-end infinite alternate;
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes type {
  0% {
    width: 0;
  }

  99.9% {
    border-right: 0.15em solid cyan;
  }

  100% {
    border: none;
  }
}

@-webkit-keyframes type {
  0% {
    width: 0;
  }

  99.9% {
    border-right: 0.15em solid cyan;
  }

  100% {
    border: none;
  }
}

@keyframes type2 {
  0% {
    width: 0;
  }

  1% {
    opacity: 1;
  }

  99.9% {
    border-right: 0.15em solid cyan;
  }

  100% {
    opacity: 1;
    border: none;
  }
}

@-webkit-keyframes type2 {
  0% {
    width: 0;
  }

  1% {
    opacity: 1;
  }

  99.9% {
    border-right: 0.15em solid cyan;
  }

  100% {
    opacity: 1;
    border: none;
  }
}

@keyframes type3 {
  0% {
    width: 0;
  }

  1% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes type3 {
  0% {
    width: 0;
  }

  1% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

@-webkit-keyframes blink {
  50% {
    border-color: transparent;
  }
}

.IntroContent {
  margin-top: 30vh;
}

.About {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  /* justify-content: space-between; */
  min-height: 100vh;
  width: 97vw;
  color: white;
  background-color: rgba(77, 77, 77, 0.198);
  
  /* background-color: black; */
}

.About h1 {
  padding-top: 25px;
}

.Skills {
  display: flex;
  flex-direction: column;
  width: 40vw;
  margin-top: 50px;
  padding-bottom: 50px;
  /* height: 45%; */
  justify-content: flex-start;
  /* background-color: darkcyan; */
}

hr {
  margin-top: 50px;
  width: 100%;
  background-color: black;
}

.Projects {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-content: center;
  /* height: 100vh; */
  width: 97vw;
  color: white;
  background-color: rgb(49, 49, 49);
  margin-top: 5%;
  /* background-color: black; */
}

.NavButtons .MuiIconButton-label {
  color: white;
}

.NavButtons .MuiIconButton-label:hover {
  color: cyan;
}

.Footer {
  color: white;
  display: flex;
  height: 10vh;
  width: 100vw;
  flex-direction: column;
  background-color: rgb(34, 34, 34);
  position: relative;
  flex-shrink: 0;
  /* bottom: 0; */
  margin-top: auto;
}

.Footer .MuiIconButton-label {
  color: white;
}

.Footer .MuiButton-label {
  color: white;
}

.Footer .MuiIconButton-label:hover {
  color: cyan;
}

.Footer .MuiButton-label:hover {
  color: cyan;
}

.MuiButton-root:hover {
  color: rgb(49, 49, 49);
}

.NavButtons .MuiButton-label {
  color: white;
}

.NavButtons .MuiButton-label:hover {
  color: cyan;
}

.scrollTop {
  /* background-color: black; */
  color: cyan;
  position: fixed;
  width: 100%;
  bottom: 20px;
  right: 3%;
  align-items: center;
  height: 80px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  -webkit-animation: fadeIn 0.2s;
          animation: fadeIn 0.2s;
  transition: opacity 0.2s;
  opacity: 1;
}

.scrollTop:hover {
  opacity: 1;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}

.scrollTop .MuiSvgIcon-root {
  width: 400px;
}

@media (max-width: 1250px) {
  .Skills {
    width: 80vw;
  }
  .Projects {
    /* height: 90vh; */
  }
  .NavButtons .MuiButton-label {
    color: cyan;
  }
}

@media (max-width: 415px) {
  .About {
    /* height: 200vh; */
    justify-content: flex-start;
    margin-bottom: 25px;
  }

  .Skills {
    /* background-color: blue; */
    justify-content: flex-end;
  }

  .Projects {
    /* height: 300vh; */
  }
  
  .NavButtons .MuiButton-label {
    color: cyan;
  }

  .scrollTop {
    right: 1%;
  }
}

@media (max-width: 376px) {
  .About {
    justify-content: flex-start;
  }

  .Skills {
    width: 90vw;
  }

  hr {
    width: 100%;
  }

  .scrollTop {
    bottom: 20px;
  }

  .Projects {
    /* height: 330vh; */
    /* height: fit-content; */
    flex-direction: column;
  }

  .scrollTop {
    right: 1%;
  }

  .IntroContent {
    width: 280px;
    align-items: center;
  }

  .IntroTyping p {
    font-size: 22px;
  }

  .IntroTyping p:nth-child(1) {
    width: 170px;
  }

  .IntroTyping p:nth-child(2) {
    width: 280px;
  }
}
.ProjectCardsContainer {
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  /* background-color: black; */
}

.CardHolder {
  display: flex;
  width: 35%;
}

.CardHolder:nth-child(3) , .CardHolder:nth-child(4) {
  margin-top: 1.5%;
  margin-bottom: 2%;
}

.CardHolder ul {
  text-align: left;
}

@media (max-width: 1250px) {
  .CardHolder {
    width: 40%;
    /* height: 45%; */
  }
}

@media (max-width: 415px) {
  .ProjectCardsContainer {
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .CardHolder {
    width: 90%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 5%;
  }
  li {
    margin: 5px 0;
  }
  
}

@media (max-width: 376px) {
  .ProjectCardsContainer {
    flex-direction: column;
    justify-content: space-evenly;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .CardHolder {
    width: 95%;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    height: 150%;
  }
  li {
    margin: 5px 0;
  }
}
.SkillsContent {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  width: 100%;
  /* height: 100%; */
}

.SkillsList {
  display: flex;
  /* height: 50%; */
  flex-direction: column;
  line-height: 2rem;
  /* background-color: darkgreen; */
}

@media (max-width: 415px) {
  .SkillsContent {
    margin-top: 10%;
  }
}

@media (max-width: 376px) {
  .SkillsList {
    /* height: 100%; */
    /* height: fit-content; */
  }
}
.Bio {
  display: flex;
  text-align: left;
  flex-direction: column;
  /* align-items: center; */
  /* height: 50%; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 40vw;
  line-height: 1.5em;
}

@media (max-width: 1250px) {
  .Bio {
    width: 80vw;
  }
}

@media (max-width: 376px) {
  .Bio {
    width: 90vw;
    /* background-color: blanchedalmond; */
  }
}
.SocialButtons .MuiSvgIcon-root {
  color: white;
}

.SocialButtons .MuiSvgIcon-root:hover {
  color: cyan;
}

@media (max-width: 1250px) {
  .SocialButtons .MuiIconButton-label{
    color: cyan;
  }
  .SocialButtons .MuiSvgIcon-root {
    color: cyan;
  }
}


.FooterLinks {
  /* padding-top: 5px; */
  display: flex;
  height: 70%;
  flex-direction: row;
  justify-content: center;

}

.FooterText {
  height: 30%;
  padding-bottom: 12px;
}

.MuiButton-label {
  color: white;
}



@media (max-width: 1250px) {
  .Footer .MuiButton-label {
    color: cyan;
  }
  .Footer .MuiSvgIcon-root {
    color: cyan;
  }
  .Footer {
    height: 15vh;
  }
}

/* @media (max-width: 376px) {

} */
