

.FooterLinks {
  /* padding-top: 5px; */
  display: flex;
  height: 70%;
  flex-direction: row;
  justify-content: center;

}

.FooterText {
  height: 30%;
  padding-bottom: 12px;
}

.MuiButton-label {
  color: white;
}



@media (max-width: 1250px) {
  .Footer .MuiButton-label {
    color: cyan;
  }
  .Footer .MuiSvgIcon-root {
    color: cyan;
  }
  .Footer {
    height: 15vh;
  }
}

/* @media (max-width: 376px) {

} */