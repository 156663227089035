.ProjectCardsContainer {
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  /* background-color: black; */
}

.CardHolder {
  display: flex;
  width: 35%;
}

.CardHolder:nth-child(3) , .CardHolder:nth-child(4) {
  margin-top: 1.5%;
  margin-bottom: 2%;
}

.CardHolder ul {
  text-align: left;
}

@media (max-width: 1250px) {
  .CardHolder {
    width: 40%;
    /* height: 45%; */
  }
}

@media (max-width: 415px) {
  .ProjectCardsContainer {
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .CardHolder {
    width: 90%;
    height: fit-content;
    margin-bottom: 5%;
  }
  li {
    margin: 5px 0;
  }
  
}

@media (max-width: 376px) {
  .ProjectCardsContainer {
    flex-direction: column;
    justify-content: space-evenly;
    height: fit-content;
  }
  .CardHolder {
    width: 95%;
    min-height: fit-content;
    height: 150%;
  }
  li {
    margin: 5px 0;
  }
}